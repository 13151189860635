<div class="flex flex-space-between" style="width: 100%; overflow-wrap: break-word;">
  <form [formGroup]="formInput" nz-form style="width: 100%;">
  <div class="flex flex-space-between">
      <div *ngIf="shouldShowSearchBox" class="search-box">
        <nz-input-group [nzPrefix]="prefixIconSearch" nzSize="default">
          <input nz-input [formControlName]="'searchKeyword'" 
          [placeholder]="getPlaceHolder('searchKeyword')"
          (keyup.enter)="doSearch()"
        >
        </nz-input-group>
        <ng-template #prefixIconSearch>
          <i nz-icon nzType="search" (click)="doSearch()" style="cursor: pointer;"></i>
        </ng-template>
      </div>

      <div class="flex" style="gap: 16px">
        <!-- filter customer -->
         <div *ngIf="shouldShowFilterClients">
          <nz-select 
            nzShowArrow nzAllowClear nzShowSearch
            nzBackdrop="true" nzMode="multiple"
            [formControlName]="'clientIds'"
            [nzPlaceHolder]="getPlaceHolder('clientIds')"
            (ngModelChange)="onFilterChange()"
            style="width: 180px"
          >
            <nz-option *ngFor="let item of listFilterClients" [nzLabel]="item.label" [nzValue]="item.value"></nz-option>
          </nz-select>
         </div>
        <!-- filter task type -->
        <div *ngIf="shouldShowFilterTaskType">
          <nz-select 
            nzShowArrow nzAllowClear nzShowSearch
            nzBackdrop="true"
            [formControlName]="'type'"
            [nzPlaceHolder]="getPlaceHolder('type')"
            (ngModelChange)="onFilterChange()"
            style="width: 180px"
          >
          <nz-option *ngFor="let item of listFilterTaskType" [nzLabel]="item.label" [nzValue]="item.value"></nz-option>
          </nz-select>
        </div>
        <!-- filter status box -->
        <div *ngIf="shouldShowFilterStatus">
          <nz-select 
            nzShowArrow nzAllowClear nzShowSearch
            nzBackdrop="true"
            [formControlName]="'status'"
            [nzPlaceHolder]="getPlaceHolder('status')"
            (ngModelChange)="onFilterChange()"
            style="width: 180px"
          >
          <nz-option *ngFor="let item of listFilterStatus" [nzLabel]="item.label" [nzValue]="item.value"></nz-option>
          </nz-select>
        </div>
      </div>
    
    </div>
  </form>
    <!-- refresh button -->
  <div style="margin-left: 20px;">
    <button nz-button (click)="onBtnRefresh()">
      <i nz-icon nzType="reload" nzTheme="outline"></i>
      Refresh
    </button>
  </div>
</div>
