import { Routes } from "@angular/router";
import { ManagerDashboardComponent } from "./dashboard";
import { PageError } from "@app/page-error/page-error";
import { ManageTaskList } from "./manage-tasks/list";

export const DASHBOARD_ROUTES: Routes = [
  {
    path: '', component: ManagerDashboardComponent, data: { title: 'Dashboard - WARP Work Manager' },
    children: [
      { path: 'manage-tasks', component: ManageTaskList, data: { title: 'Manage Task - Warp Work' } },
      { path: '**', component: PageError }
    ]
  }
]