import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { Component, ViewChild } from "@angular/core";
import { BaseList } from "@app/base-components/list";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { WorkQueueTabList } from "./tabs-list/work-queue-tab";
import { ManageTaskService } from "./services/manage-tasks.service";
import { ManageTaskHeaderFilter } from "./header-filter";
import { environment } from "@env/environment";
import { ResolvedTabList } from "./tabs-list/resolved-tab";
import { EscalationTabList } from "./tabs-list/escalation-tab";
import { PausedTabList } from "./tabs-list/paused-tab";


const CustomInputs = [
  NgIf, NgFor, NgClass,
  NgSwitch, NgSwitchCase,
  NzIconModule,
  NzTabsModule,

  ManageTaskHeaderFilter,
  WorkQueueTabList,
  ResolvedTabList,
  EscalationTabList,
  PausedTabList,
]

@Component({
  selector: '[manage-task-list]',
  standalone: true,
  imports: [CustomInputs],
  providers: [ManageTaskService],
  templateUrl: './list.html',
  styleUrls: ['./list.scss']
})

export class ManageTaskList extends BaseList {
  private readonly TAB_COUNT = 4;
  selectedTabIndex = 0;

  // Điều kiện filter mặc định của từng tab
  defaultTabConditions = {
    'escalation': {
      status: [Const.WorkTaskStatus.escalated]
    },
    'workqueue': {
      status: [
        Const.WorkTaskStatus.pending,
        Const.WorkTaskStatus.inProcess
      ]
    },
    'resolved': {
      status: [Const.WorkTaskStatus.completed]
    },
    'paused': {
      status: [Const.WorkTaskStatus.paused]
    }
  }

  @ViewChild('headerFilter') headerFilter: ManageTaskHeaderFilter;

  constructor(private manageTaskService: ManageTaskService) {
    super();
    let params = Utils.parseQueryStringFromUrl(this.router.url);
    if (params?.tab) {
      let tabIndex = Utils.toNumber(params.tab, 0);
      if (tabIndex >= this.TAB_COUNT) {
        this.selectedTabIndex = 0;
      }
      else this.selectedTabIndex = tabIndex;
    }
    else {
      this.selectedTabIndex = 0;
    }
    let filter = this.getTabDefaultFilter(this.selectedTabIndex);
    filter = JSON.stringify(filter);
    this.routeWithQueryUrl({ tab: this.selectedTabIndex, filter }, true)
  }

  ngOnInit() {
    super.ngOnInit();
    this.autoShrinkSideBar();
    this.manageTaskService.fetchOperators();
  }

  protected getApiUrl(): string {
    return `${environment.backendUrl}/${Const.API_LIST_TASK}`;
  }

  getTabDefaultFilter(tabIndex: number) {
    switch (tabIndex) {
      case Const.ManageTaskTabIndex.escalation:
        return this.defaultTabConditions.escalation;
      case Const.ManageTaskTabIndex.workqueue:
        return this.defaultTabConditions.workqueue;
      case Const.ManageTaskTabIndex.resolved:
        return this.defaultTabConditions.resolved;
      case Const.ManageTaskTabIndex.paused:
        return this.defaultTabConditions.paused;
      default:
        return {};
    }
  }

  onTabIndexChange() {
    this.manageTaskService.resetPaginationData();
    let filter = this.getTabDefaultFilter(this.selectedTabIndex);
    filter = JSON.stringify(filter);
    this.routeWithQueryUrl({ tab: this.selectedTabIndex, filter}, true);
  }

  protected onGetDataSucceeded(resp) {
    let data = resp?.data || {};
    let page = Utils.toNumber(this.queryParams.page, 1);
    data['pageIndex'] = page;
    this.manageTaskService.setPaginationData(resp?.data);
  }

  reload() {
    this.getData();
  }

  onFilterChange() {
    let params = this.headerFilter.getData_JSON();
    let currentFilter = JSON.parse(this.queryParams.filter)
    let defaultFilter: any = this.getTabDefaultFilter(this.selectedTabIndex);
    let status = params?.status || defaultFilter?.status;
    let filter = currentFilter || {};
    filter = {...filter, status}
    if (params?.type) {
      filter['type'] = params.type;
    }
    if (params?.clientIds) {
      filter['clientIds'] = params.clientIds;
    }
    filter = JSON.stringify(filter);
    let search = params?.searchKeyword;
    let page = 1;
    // các params không đổi thì giữ nguyên
    this.routeWithQueryUrl({search, filter, page});
  }
}