import { BaseComponent } from "@abstract/BaseComponent";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { getInjector } from "@services/index";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzModalService } from "ng-zorro-antd/modal";

const CustomInputs = [
  NgIf, NgFor, NgClass,
  NzIconModule,
  NzDropDownModule,
  NzButtonModule
]

@Component({
  selector: 'app-topnav',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class TopnavAppComponent extends BaseComponent {

  public txtOK = "OK";
  public txtCancel = "Cancel";
  public isOnline = true;
  public navMenuItems;

  ngOnInit(): void {
    super.ngOnInit();
    this.initMenuItems();
  }

  get userName() {
    return this.authUser ? this.authUser.fullName || this.authUser.email : '';
  }

  get topbarTitleString(): string {
    return this.topbarTitleFixed;
  }

  private topbarTitleFixed: string = '';

  public setTopbarTitleFixed(str: string) {
    this.topbarTitleFixed = str;
  }

  onMenuItemClick(item) {
    if (!item.key) {
      return;
    }
    switch (item.key) {
      case 'logout':
        this.confirmLogout();
        break;
      default:
        break;
    }
  }

  private initMenuItems() {
    this.navMenuItems = [
      {
        label: 'Log out',
        key: 'logout',
        nzIcon: 'logout'
      }
    ]
  }

  private confirmLogout() {
    const modalService = getInjector().get(NzModalService);
    modalService.confirm({
      nzContent: 'Are you sure you want to sign out.',
      nzWrapClassName: 'vertical-center-modal',
      nzOkText: this.txtOK,
      nzOkType: 'primary',
      nzOnOk: () => {
        this.api.logout();
        this.goLogin();
      },
      nzCancelText: this.txtCancel,
      nzOnCancel: () => {}
    })
  }
}