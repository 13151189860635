import { NgClass, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseFormItem } from "@app/base-components/form-item";
import { Const } from "@const/Const";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzFormModule } from "ng-zorro-antd/form";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";

const CustomInputs = [
  FormsModule,
  ReactiveFormsModule,
  NzFormModule,
  NgIf, NgFor, NgClass,
  NzTableModule,
  NzInputModule,
  NzSelectModule,
  NzButtonModule,
  NzIconModule,
]

@Component({
  selector: '[manage-task-header-filter]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class ManageTaskHeaderFilter extends BaseFormItem {
  @Input() tabIndex: number = Const.ManageTaskTabIndex.workqueue;
  @Output() filterChange = new EventEmitter<any>();
  
  protected formGroupDeclaration: FormGroupDeclaration = {
    searchKeyword: { label: '', placeHolder: 'Search by Route, Shipment, REF, Customer', notAcceptEmpty: true },
    status: { label: '', placeHolder: 'Select Task Status'},
    type: { label: '', placeHolder: 'Select Task Type'},
    clientIds: { label: '', placeHolder: 'Select Customer'},
  }

  listFilterStatus = [
    { label: 'Open', value: [Const.WorkTaskStatus.pending] },
    { label: 'Working', value: [Const.WorkTaskStatus.inProcess] },
  ]

  listFilterTaskType = [
    { label: Const.TrackingTaskLabel.routeConfirmation, value: Const.TrackingTask.routeConfirmation },
  ]

  get listFilterClients() {
    return Const.listFilterClients;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  get shouldShowSearchBox() {
    return true;
  }

  get shouldShowFilterClients() {
    return this.tabIndex === Const.ManageTaskTabIndex.workqueue;
  }

  get shouldShowFilterTaskType() {
    return this.tabIndex === Const.ManageTaskTabIndex.workqueue;
  }

  get shouldShowFilterStatus() {
    return this.tabIndex === Const.ManageTaskTabIndex.workqueue;
  }

  doSearch() {
    this.filterChange.emit();
  }

  onFilterChange() {
    this.filterChange.emit();
  }

  onBtnRefresh() {
    for(let key of this.formInputKeys || []) {
      this.setItemValue(key, null);
    }
  }

  getData_JSON() {
    return this.getFormData_JSON(true);
  }
  
}