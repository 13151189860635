import { PaginationData as PaginationDataInterface } from "@wearewarp/types/rest-api/common";
import { Injectable } from "@angular/core";
import { ApiService } from "@services/api.service";
import { UIHelper } from "@services/UIHelper";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { Const } from "@const/Const";
import { environment } from "@env/environment";
import { User } from "@wearewarp/types/data-model";
import to from 'await-to-js';

@Injectable()
export class ManageTaskService {
  public tabIndex: BehaviorSubject<number> = new BehaviorSubject(null);
  public paginationData: BehaviorSubject<PaginationDataInterface<any> > = new BehaviorSubject<PaginationDataInterface<any> >(null);
  public listOperators: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(null);

  constructor(private api: ApiService) {
  }

  public setPaginationData(data) {
    let pagination: PaginationDataInterface<any> = {
      skip: data?.skip || 0,
      total: data?.total || 0,
      limit: data?.limit || Const.PAGINATION_LIMIT,
      list_data: data?.list_data || [],
      count: data?.list_data?.length || 0,
      pageIndex: data?.pageIndex || 1
    }
    this.paginationData.next(pagination);
  }

  public resetPaginationData() {
    this.paginationData.next({
      skip: 0, total: 0, limit: Const.PAGINATION_LIMIT,
      list_data: [], count: 0, pageIndex: 1
    })
  }

  public async fetchOperators() {
    try {
      let url = `${environment.backendUrl}/${Const.API_LIST_OPERATORS}`;
      let resp = await lastValueFrom(this.api.GET(url));
      let users = resp?.data?.list_data;
      this.listOperators.next(users)
    }
    catch (e) {
      UIHelper.showErr(e);
    }

  }

  public getListOperators() {
    return this.listOperators.getValue();
  }

  public async assignTaskToUser(taskId, userId) {
    if (!taskId) UIHelper.showErr(`taskId is missing`);
    if (!userId) UIHelper.showErr(`userId is missing`);
    
    let url = `${environment.backendUrl}/${Const.API_ASSIGN_TASK(taskId)}`;
    let params = { userId: userId }
    const [err, resp] = await to(lastValueFrom(this.api.POST(url, params)));
    if (err) {
      UIHelper.showErr(err);
    }
  }

  
}