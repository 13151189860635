import { BaseComponent } from "@abstract/BaseComponent";
import { Directive, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { PaginationData } from "@app/model/PaginationData";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import { NzTableComponent } from "ng-zorro-antd/table";


/**
 * Dùng khi component chỉ cần những tính năng của table
 * Truyền data vào chứ không gọi api để lấy data như baselist
 */
@Directive()
export abstract class BaseTable<T = any> extends BaseComponent {
  public paginationData: PaginationData<T> = new PaginationData<T>();
  get listData(): Array<T> { return this.paginationData ? this.paginationData.list_data : [] }
  get totalCount(): number { return this.paginationData ? this.paginationData.total : 0 }
  get currentPageSize(): number { return Utils.toNumber((this.queryParams || {}).limit, Const.PAGINATION_LIMIT); }
  get limit(): number { return this.currentPageSize; }
  
  public pageIndex = 1;
  selectAll = false;
  selectedItems = [];

  @ViewChild('nzTable', {static: false}) nzTable: NzTableComponent<any>;
  @Input() isLoading: boolean = false;
  @Output() onPageIndexChange = new EventEmitter<number>();
  @Output() reload = new EventEmitter<any>();

  setPaginationData(data) {
    this.paginationData.setData(data);
    if (data?.pageIndex) {
      this.pageIndex = data.pageIndex;
    } else {
      this.pageIndex = Utils.toNumber(this.queryParams.page, 1);
    }
    if (this.nzTable && this.nzTable.nzPageIndex != this.pageIndex) {
      this.nzTable.nzPageIndex = this.pageIndex;
    }
  }

  onDataListPageChanged(page) {
    this.selectAll = false;
    this.onPageIndexChange.emit(page);
  }
}