import { NgClass, NgFor, NgIf } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BaseFormDialog } from "@app/dialogs/base-form-dialog";
import { Const } from "@const/Const";
import { UIHelper } from "@services/UIHelper";
import { Utils } from "@services/utils";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { NZ_MODAL_DATA } from "ng-zorro-antd/modal";
import { NzSelectModule } from "ng-zorro-antd/select";


const CustomInputs = [
  NgIf, NgFor, NgClass,
  ReactiveFormsModule,
  FormsModule,
  NzFormModule,
  NzButtonModule,
  NzSelectModule,
]

@Component({
  selector: '[bulk-assign-task-dialog]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class BulkAssignTaskDialog extends BaseFormDialog {
  protected formGroupDeclaration: FormGroupDeclaration = {
    assignTo: { label: 'Assign To', required: true }
  }
  public isLoading: boolean = false;
  public tasks: any[] = [];
  public listOperators: any[] = [];

  constructor(@Inject(NZ_MODAL_DATA) data: any) {
    super(data);
    this.tasks = data.tasks || [];
    this.listOperators = data.listOperators || [];
  }

  get assignTaskDesc() {
    return `Assign ${this.tasks.length} tasks to`;
  }

  getFullName(user) {
    return Utils.getFullName(user);
  }

  onBtnBulkAssignTask() {
    const assignTo = this.getItemValue('assignTo');
    if (!assignTo) {
      UIHelper.showErr('Assign To is required');
      return;
    }
    this.isLoading = true;
    const url = Const.API_BULK_ASSIGN_TASK();
    const params = {
      assignTo,
      taskIds: this.tasks.map(task => task.id)
    }
    this.api.POST(url, params).subscribe({
      next: (resp) => {
        UIHelper.showSuccess('Bulk assign successfully');
        this.isLoading = false;
        this.onUpdateSuccess(resp);
      },
      error: (err) => {
        UIHelper.showErr(err);
        this.isLoading = false;
      }
    });
  }

  onBtnCancel() {
    this.closeDialog();
  }
}