import { Component, Inject, Input } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BaseFormDialog } from "@app/dialogs/base-form-dialog";
import { Const } from "@const/Const";
import { UIHelper } from "@services/UIHelper";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { NZ_MODAL_DATA } from "ng-zorro-antd/modal";
import { NzRadioModule } from 'ng-zorro-antd/radio';


const CustomInputs = [
  ReactiveFormsModule,
  FormsModule,
  NzFormModule,
  NzButtonModule,
  NzRadioModule,
]

@Component({
  selector: '[remove-work-task-dialog]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class RemoveWorkTaskDialog extends BaseFormDialog {
  protected formGroupDeclaration: FormGroupDeclaration = {
    removeType: { label: '', initialValue: 'taskOnly', required: true }
  }
  public taskId;
  public jobId;
  constructor(@Inject(NZ_MODAL_DATA) data: any) {
    super(data);
    this.taskId = data.taskId;
    this.jobId = data.jobId;
  }
  public isLoading: boolean = false;

  onBtnRemoveTask() {
    let url = '';
    let removeType = this.getItemValue('removeType');
    switch (removeType) {
      case 'taskOnly': 
        if (!this.taskId) { UIHelper.showErr('taskId is required'); return; }
        url = Const.API_REMOVE_TASK(this.taskId); break;
      case 'allRouteTasks':
        if (!this.jobId) { UIHelper.showErr('jobId is required'); return; }
        url = Const.API_REMOVE_ALL_ROUTE_TASKS(this.jobId); break;
      default:
        UIHelper.showErr('Invalid removeType');
        return;
    }
    this.isLoading = true;
    this.api.DELETE(url).subscribe({
      next: (resp) => {
        UIHelper.showSuccess('Remove successfully');
        this.isLoading = false;
        this.onUpdateSuccess(resp);
      },
      error: (err) => {
        UIHelper.showErr(err);
        this.isLoading = false;
      }
    });
  }

  onBtnCancel() {
    this.closeDialog();
  }
}