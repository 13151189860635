import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { NzTableModule } from 'ng-zorro-antd/table';
import { ManageTaskService } from "../../services/manage-tasks.service";
import { Subscription } from "rxjs";
import { NzIconModule } from "ng-zorro-antd/icon";
import { GetWhenByService } from "@services/getWhenBy.service";
import { IByData } from "@app/interfaces";
import { BaseTable } from "@app/base-components/base-table";
import { NzSelectModule } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { Utils } from "@services/utils";
import { Stop, WorkTask } from "@wearewarp/types/data-model";
import { NzTagModule } from 'ng-zorro-antd/tag';
import { DateUtil } from "@services/date-util";
import { WorkTaskAnswerType } from "@wearewarp/types/data-model/types/work-queue";
import { InChargeHistory } from "@wearewarp/types/data-model/types/WorkTask";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";


const CustomInputs = [
  CommonModule,
  FormsModule,
  NgIf, NgFor, NgClass,
  NzIconModule,
  NzTableModule,
  NzSelectModule,
  NzTagModule,
  NzToolTipModule
]

@Component({
  selector: '[resolved-tab-list]',
  standalone: true,
  imports: [CustomInputs],
  providers: [GetWhenByService],
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class ResolvedTabList extends BaseTable {
  private manageServiceSubscription: Subscription = new Subscription();
  expandSet = new Set<number>();

  displayInfo: any = {};

  constructor(private manageTaskService: ManageTaskService, private getWhenByService: GetWhenByService){
    super();
  }

  ngOnInit() {
    this.manageServiceSubscription.add(this.manageTaskService.paginationData.subscribe((value) => {
      if (value) {
        this.expandSet.clear();
        this.setPaginationData(value);
        this.buildDisplayInfo();  
      }
    }))
  }

  ngOnDestroy(): void {
    this.manageServiceSubscription?.unsubscribe();
  }

  async buildDisplayInfo() {
    this.displayInfo['listData'] = await Promise.all(this.listData?.map(async item => {
      let inChargeIds = Utils.uniqElementsArray(item?.inChargeHistories?.map(it => it.inChargeId.split('_')?.[1]) || []);
      let inChargeInfos = await Promise.all(inChargeIds.map(async id => {
        return await this.getWhenByData({byId: id});
      }))
      inChargeInfos = inChargeInfos.map(it => {
        return {...it, fullName: this.getAssigneeFullName(it)}
      })
      let inChargeHistories = item.inChargeHistories || [];
      inChargeHistories = inChargeHistories.map(history => {
        let inChargeId = history.inChargeId.split('_')?.[1];
        let duration = this.getSessionWorkDuration(history);
        let answerText = this.getTaskAnswerText(history.answerType);
        return {
          ...history,
          answerText: answerText,
          workDuration: this.getDurationText(duration),
          inchargeInfo: inChargeInfos?.find(it => it.id == inChargeId) || null
        }
      })
      let taskDuration = this.getTaskWorkDuration(item);
      let taskCompletedTime = this.getTaskCompletedTime(item);

      return {
        ...item,
        taskName: this.getTaskName(item),
        statusText: this.getTaskStatus(item),
        workDuration: this.getDurationText(taskDuration),
        completedTime: taskCompletedTime,
        inChargeInfos,
        inChargeHistories,
        // jobInfo
        jobCode: item.jobInfo?.code || 'N/A',
        stopDesc: this.getStopDesc(item) || '',
      }
    }))
  }

  onExpandChange(item) {
    if (!item) return;
    if (!this.shouldShowExpand(item)) return;
    let id = item?.id;
    if (this.expandSet.has(id)) this.expandSet.delete(id);
    else this.expandSet.add(id);
  }

  shouldShowExpand(item): boolean {
    let inChargeHistories = item.inChargeHistories || [];
    return inChargeHistories.length > 1;
  }

  getTaskName(item) {
    return Const.TrackingTaskLabel[item?.type] || '';
  }

  getTaskStatus(item) {
    return Const.WorkTaskStatusText(item?.status);
  }

  getAssigneeFullName(inChargeInfo) {
    if (!inChargeInfo) return '';
    return this.getWhenByService.getFullName(inChargeInfo);
  }

  async getWhenByData(data) {
    if (!data) return;
    let params: IByData = {
      collection: data?.collection || 'users',
      id: data?.byId,
      warpId: data?.by
    }
    return this.getWhenByService.getWhenByData(params);
  }

  getStopDesc(item) {
    if (!item) return '';
    let stops: Stop[] = item?.jobInfo?.stops || [];
    let stopId = item?.object?.metadata?.stopId;
    let stop = stops?.find(it => it.id === stopId);
    if (!stopId || !stop) return '';
    let stopIndex = stops.findIndex(it => it.id === stopId) + 1;
    let stopType;
    if (stop.type === Const.RouteTaskType.PICKUP) stopType = 'PU';
    else if (stop.type === Const.RouteTaskType.DROPOFF) stopType = 'DO';
    return `Stop ${stopIndex} - ${stopType}`;
  } 

  getTaskWorkDuration(task: WorkTask) {
    if (!task) return 0;
    let inChargeHistories = task?.inChargeHistories || [];
    let totalDuration = 0; // mins
    for (let item of inChargeHistories) {
      totalDuration += this.getSessionWorkDuration(item);
    }
    return totalDuration;
  }

  getSessionWorkDuration(inChargeInfo): number {
    if (!inChargeInfo?.assignTime || !inChargeInfo?.completeTime) {
      return 0;
    }
    return DateUtil.getTimeDurationFromWindow({
      from: inChargeInfo?.assignTime,
      to: inChargeInfo?.completeTime
    })
  }

  // input duration: ms
  getDurationText(duration: number) {
    if (!duration) return '';
    let mins = Math.floor(duration / (60 * 1000));
    return DateUtil.getParseDuration(mins);
  }

  getDisplayOrdinalNum(idx: number) {
    return Utils.getDisplayOrdinalNum(idx);
  } 

  getNumTaskInCharge(item) {
    let count = item?.inChargeHistories?.length || 0;
    return `${count} attempts`;
  }

  getTaskCompletedTime(item) {
    if (!item) return 'N/A';
    let inChargeHistories = item?.inChargeHistories || [];
    let lastInCharge = inChargeHistories[inChargeHistories.length - 1];
    let completeTime = lastInCharge?.completeTime;
    if (!completeTime) return 'N/A';
    return this.getDisplayLocalTime(completeTime);
  }

  getTaskAnswerText(answerType: WorkTaskAnswerType) {
    return Const.getTaskAnswerText(answerType) || 'N/A';
  }

  // Lấy theo timezone của user
  getDisplayLocalTime(time: string) {
    return DateUtil.displayLocalTime(time, DateUtil.getLocalTimeZone(), 'MMM DD, YYYY h:mm A');
  }

  isTaskAutoCompleted(item): boolean {
    return item?.isAutoCompleted || false;
  }
  
  getHistoryItemWarning(history: InChargeHistory) {
    let data = history?.data || {};
    let desc = '';
    for (let key in data) {
      if (data[key]) desc += `${key}: ${data[key]}\n`;
    }
    return desc || 'N/A';
  }

  routeToAdminDispatch(item) {
    const jobId = item?.object?.jobId || '';
    return Const.routeAdminDispatch(jobId);
  }
}