<div>
  <nz-table
    #nzTable nzBordered="true"
    [nzData]="displayInfo.listData" nzSize="small"
    [nzTotal]="totalCount" [nzFrontPagination]="false"
    [nzShowPagination]="totalCount > listData.length"
    [nzPageIndex]="pageIndex"
    [nzLoading]="isLoading"
    [nzLoadingIndicator]="tplLoading"
    (nzPageIndexChange)="onDataListPageChanged($event)"
    >
    <ng-template #tplLoading>
      <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    </ng-template>
    <thead>
      <tr>
        <th>Task</th>
        <th nzWidth="220px">Route</th>
        <th>Completed time (Local timezone)</th>
        <th nzWidth="150px">Work duration</th>
        <th nzWidth="200px">Assignee</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of displayInfo.listData; let i = index">
        <tr>
          <td (click)="onExpandChange(item)">
            <div class="flex flex-space-between" style="cursor: pointer;">
              <div>{{ item?.taskName || 'N/A' }}</div>
              <div *ngIf="shouldShowExpand(item)" style="min-width: 90px;">
                <span class="ordinal-number">{{ getNumTaskInCharge(item) }}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="flex flex-space-between">
              <div><a [href]="routeToAdminDispatch(item)" target="_blank">{{ item.jobCode }}</a></div>
              <div class="light-text">{{ item.stopDesc }}</div>
            </div>
          </td>
          <td>
            <span *ngIf="isTaskAutoCompleted(item)"><nz-tag [nzColor]="'green'">Auto completed</nz-tag></span>
            <span *ngIf="!isTaskAutoCompleted(item)">{{  item.completedTime }}</span>
          </td>
          <td>{{ item.workDuration }}</td>
          <td class="btn-assign">
            <div *ngIf="item.inChargeInfos?.length > 0">
              <div *ngFor="let assignee of item.inChargeInfos">{{ assignee.fullName }}</div>
            </div>
          </td>
        </tr>
        <ng-container *ngIf="shouldShowExpand(item) && expandSet.has(item.id)">
          <tr *ngFor="let history of item.inChargeHistories || []; let i = index">
            <td colspan="2">
              <div class="flex flex-space-between left20">
                <div>{{ item.taskName }}<span class="ordinal-number left10">{{ getDisplayOrdinalNum(i+1) }}</span></div>
                <div *ngIf="history.answerText !== 'N/A'" class="center-children">
                  <i nz-icon nzType="warning" 
                    nz-tooltip [nzTooltipTitle]="getHistoryItemWarning(history)"
                    style="margin-right: 8px; padding-bottom: 4px; color: #faad14;">
                  </i>
                  {{ history.answerText }}
                </div>
              </div>
            </td>
            <td>{{ getDisplayLocalTime(history.completeTime) || 'N/A'}}</td>
            <td>{{ history.workDuration }}</td>
            <td>{{ history.inchargeInfo?.fullName || 'N/A' }}</td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </nz-table>
</div>