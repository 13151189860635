<form [formGroup]="formInput" nz-form class="form-container">
  <div class="flex">
    <div class="bottom10" style="font-size: 20px;">Bulk Assign Tasks</div>
  </div>
  <div>
    <div class="bottom10">
      <span>{{ assignTaskDesc }}</span>
    </div>
    <nz-select
      style="width: 50%;"
      [formControlName]="'assignTo'"
      nzShowSearch
      nzBackdrop="true"
      [nzPlaceHolder]="'Select Operator'"
    >
      <nz-option *ngFor="let user of listOperators" [nzValue]="user.id" [nzLabel]="getFullName(user)"></nz-option>
    </nz-select>
  </div>
  <div class="separator h top10 bottom10"></div>
  <div class="form-footer">
    <button nz-button nzType="primary" [nzLoading]="isLoading" [disabled]="isLoading" (click)="onBtnBulkAssignTask()">Bulk Assign</button>
    <button nz-button nzType="default" [disabled]="isLoading" (click)="onBtnCancel()">Cancel</button>
  </div>
</form>
