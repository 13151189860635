import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { NzTableModule } from 'ng-zorro-antd/table';
import { ManageTaskService } from "../../services/manage-tasks.service";
import { Subscription } from "rxjs";
import { NzIconModule } from "ng-zorro-antd/icon";
import { GetWhenByService } from "@services/getWhenBy.service";
import { IByData } from "@app/interfaces";
import { BaseTable } from "@app/base-components/base-table";
import { NzSelectModule } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { Utils } from "@services/utils";
import { Stop } from "@wearewarp/types/data-model";
import { DialogService } from "@app/dialogs/dialog.service";
import { RemoveWorkTaskDialog } from "../components/remove-task-dialog";
import { UIHelper } from "@services/UIHelper";
import { NzButtonModule } from "ng-zorro-antd/button";
import { DateUtil } from "@services/date-util";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";

const CustomInputs = [
  CommonModule,
  FormsModule,
  NgIf, NgFor, NgClass,
  NzIconModule,
  NzTableModule,
  NzSelectModule,
  NzButtonModule,
  NzToolTipModule,
  RemoveWorkTaskDialog
]

@Component({
  selector: '[paused-tab-list]',
  standalone: true,
  imports: [CustomInputs],
  providers: [GetWhenByService],
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class PausedTabList extends BaseTable {
  private manageServiceSubscription: Subscription = new Subscription();

  displayInfo: any = {};
  listOperators = [];
  listOperatorIds = [];

  constructor(private manageTaskService: ManageTaskService, private getWhenByService: GetWhenByService){
    super();
  }

  ngOnInit() {
    this.manageServiceSubscription.add(this.manageTaskService.paginationData.subscribe((value) => {
      if (value) {
        this.setPaginationData(value);
        this.buildDisplayInfo();  
      }
    }))
    this.manageServiceSubscription.add(this.manageTaskService.listOperators.subscribe(() => {
      this.listOperators = this.manageTaskService.getListOperators();
      this.listOperatorIds = this.listOperators?.map(it => it.id);
    }))
  }

  ngOnDestroy(): void {
    this.manageServiceSubscription?.unsubscribe();
  }

  async buildDisplayInfo() {
    this.displayInfo['listData'] = await Promise.all(this.listData?.map(async item => {
      let byId = item?.inChargeId?.split('_')?.[1] || '';
      let inChargeInfo = byId ? await this.getWhenByData({ byId }) : null;
      return {
        ...item,
        status: item.status,
        taskName: this.getTaskName(item),
        statusText: this.getTaskStatus(item),
        assignee: inChargeInfo ? this.getAssigneeFullName(inChargeInfo) : '',
        inChargeId: byId,
        pauseWarning: this.getPauseWarning(item),
        // jobInfo
        jobCode: item.jobInfo?.code || 'N/A',
        stopDesc: this.getStopDesc(item) || '',
      }
    }))
  }

  getTaskName(item) {
    return Const.TrackingTaskLabel[item?.type] || '';
  }

  getTaskStatus(item) {
    return Const.WorkTaskStatusText(item?.status);
  }

  getAssigneeFullName(inChargeInfo) {
    if (!inChargeInfo) return '';
    return this.getWhenByService.getFullName(inChargeInfo);
  }

  async getWhenByData(data) {
    if (!data) return;
    let params: IByData = {
      collection: data?.collection || 'users',
      id: data?.byId,
      warpId: data?.by
    }
    return this.getWhenByService.getWhenByData(params);
  }

  isAssigned(item) {
    return item?.inChargeId;
  }

  getFullName(user) {
    return Utils.getFullName(user);
  }

  async onChangeTaskAssignee(item) {
    if (item?.inChargeId) {
      await this.manageTaskService.assignTaskToUser(item.id, item.inChargeId);
      this.reload.emit();
    }
  }

  getStopDesc(item) {
    if (!item) return '';
    let stops: Stop[] = item?.jobInfo?.stops || [];
    let stopId = item?.object?.metadata?.stopId;
    let stop = stops?.find(it => it.id === stopId);
    if (!stopId || !stop) return '';
    let stopIndex = stops.findIndex(it => it.id === stopId) + 1;
    let stopType;
    if (stop.type === Const.RouteTaskType.PICKUP) stopType = 'PU';
    else if (stop.type === Const.RouteTaskType.DROPOFF) stopType = 'DO';
    return `Stop ${stopIndex} - ${stopType}`;
  }

  getPauseWarning(item) {
    let inChargeHistories = item?.inChargeHistories || [];
    let index = inChargeHistories.length - 1;
    if (index < 0) return '';
    let history = inChargeHistories[index];
    if (!history.pauseTime) return '';
    return `Paused at ${this.$formatDate(history.pauseTime)} (EST).\nReason: ${history.data?.reason || 'N/A'}`;
  }

  // EST
  $formatDate = (date: Date) => {
    return DateUtil.displayLocalTime(date, 'America/New_York', 'MM/DD/YYYY hh:mm A');
  }

  isAssignedNotOperator(item) {
    return item?.inChargeId && !this.listOperatorIds.includes(item.inChargeId);
  }

  routeToAdminDispatch(item) {
    const jobId = item?.object?.jobId || '';
    return Const.routeAdminDispatch(jobId);
  }

  onBtnDeleteTask(item) {
    DialogService.openFormDialog(RemoveWorkTaskDialog, {
      nzData: {
        taskId: item.id,
        jobId: item.object?.jobId,
        closeOnSuccess: true,
        updateSuccess: () => {
          this.reload.emit();
        }
      },
      nzMaskClosable: true,
      nzClassName: 'modal-no-padding',
      nzWidth: '460px'
    });
  }

  onBtnResumeTask(item) {
    UIHelper.confirm(`Are you sure you want to resume this task?`, async () => {
      let url = Const.API_RESUME_TASK(item.id);
      this.api.POST(url, {}).subscribe({
        next: (resp) => {
          UIHelper.showSuccess('Resume successfully');
          this.reload.emit();
        },
        error: (err) => {
          UIHelper.showErr(err);
        }
      })
    })
  }
  
}