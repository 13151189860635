import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { NzTableModule } from 'ng-zorro-antd/table';
import { ManageTaskService } from "../../services/manage-tasks.service";
import { Subscription } from "rxjs";
import { NzIconModule } from "ng-zorro-antd/icon";
import { GetWhenByService } from "@services/getWhenBy.service";
import { IByData } from "@app/interfaces";
import { BaseTable } from "@app/base-components/base-table";
import { NzSelectModule } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { Utils } from "@services/utils";
import { Stop } from "@wearewarp/types/data-model";
import { DateUtil } from "@services/date-util";
import { DialogService } from "@app/dialogs/dialog.service";
import { RemoveWorkTaskDialog } from "../components/remove-task-dialog";
import { NzButtonModule } from "ng-zorro-antd/button";
import { BulkAssignTaskDialog } from "../components/bulk-assign-task-dialog";

const CustomInputs = [
  CommonModule,
  FormsModule,
  NgIf, NgFor, NgClass,
  NzIconModule,
  NzTableModule,
  NzSelectModule,
  NzButtonModule,
  RemoveWorkTaskDialog,
  BulkAssignTaskDialog
]

@Component({
  selector: '[work-queue-tab-list]',
  standalone: true,
  imports: [CustomInputs],
  providers: [GetWhenByService],
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class WorkQueueTabList extends BaseTable {
  private manageServiceSubscription: Subscription = new Subscription();

  displayInfo: any = {};
  listOperators = [];
  listOperatorIds = [];
  //select task
  public selectAll = false;
  public indeterminate = false;
  public setOfSelectedId: any = {};

  constructor(private manageTaskService: ManageTaskService, private getWhenByService: GetWhenByService){
    super();
  }

  ngOnInit() {
    this.manageServiceSubscription.add(this.manageTaskService.paginationData.subscribe((value) => {
      if (value) {
        this.setPaginationData(value);
        this.buildDisplayInfo();  
        this.resetListSelected();
      }
    }))
    this.manageServiceSubscription.add(this.manageTaskService.listOperators.subscribe(() => {
      this.listOperators = this.manageTaskService.getListOperators();
      this.listOperatorIds = this.listOperators?.map(it => it.id);
    }))
  }

  ngOnDestroy(): void {
    this.manageServiceSubscription?.unsubscribe();
  }

  async buildDisplayInfo() {
    this.displayInfo['listData'] = await Promise.all(this.listData?.map(async item => {
      let byId = item?.inChargeId?.split('_')?.[1] || '';
      let inChargeInfo = byId ? await this.getWhenByData({ byId }) : null;
      return {
        ...item,
        status: item.status,
        taskName: this.getTaskName(item),
        statusText: this.getTaskStatus(item),
        assignee: inChargeInfo ? this.getAssigneeFullName(inChargeInfo) : '',
        inChargeId: byId,
        workDuration: this.getWorkedDuration(item),
        // jobInfo
        jobCode: item.jobInfo?.code || 'N/A',
        stopDesc: this.getStopDesc(item) || '',
      }
    }))
  }

  getTaskName(item) {
    return Const.TrackingTaskLabel[item?.type] || '';
  }

  getTaskStatus(item) {
    return Const.WorkTaskStatusText(item?.status);
  }

  getAssigneeFullName(inChargeInfo) {
    if (!inChargeInfo) return '';
    return this.getWhenByService.getFullName(inChargeInfo);
  }

  async getWhenByData(data) {
    if (!data) return;
    let params: IByData = {
      collection: data?.collection || 'users',
      id: data?.byId,
      warpId: data?.by
    }
    return this.getWhenByService.getWhenByData(params);
  }

  isTaskWorking(item) {
    return item?.status === Const.WorkTaskStatus.inProcess;
  }

  isAssigned(item) {
    return item?.inChargeId;
  }

  getFullName(user) {
    return Utils.getFullName(user);
  }

  // showChangeAssignee(item) {
  //   return item?.status === Const.WorkTaskStatus.inProcess;
  // }

  async onChangeTaskAssignee(item) {
    if (item?.inChargeId) {
      await this.manageTaskService.assignTaskToUser(item.id, item.inChargeId);
      this.reload.emit();
    }
  }

  getStopDesc(item) {
    if (!item) return '';
    let stops: Stop[] = item?.jobInfo?.stops || [];
    let stopId = item?.object?.metadata?.stopId;
    let stop = stops?.find(it => it.id === stopId);
    if (!stopId || !stop) return '';
    let stopIndex = stops.findIndex(it => it.id === stopId) + 1;
    let stopType;
    if (stop.type === Const.RouteTaskType.PICKUP) stopType = 'PU';
    else if (stop.type === Const.RouteTaskType.DROPOFF) stopType = 'DO';
    return `Stop ${stopIndex} - ${stopType}`;
  }

  isAssignedNotOperator(item) {
    return item?.inChargeId && !this.listOperatorIds.includes(item.inChargeId);
  }

  getWorkedDuration(item): string {
    if (item?.status !== Const.WorkTaskStatus.inProcess) return 'N/A';
    let inChargeHistories = item?.inChargeHistories || [];
    let lastInCharge = inChargeHistories[inChargeHistories.length - 1];
    let assignTime = lastInCharge?.assignTime;
    if (!assignTime) return 'N/A';
    const duration = DateUtil.getTimeDurationFromWindow({
      from: assignTime,
      to: new Date().toISOString()
    });
    let mins = Math.floor(duration / (60 * 1000));
    return DateUtil.getParseDuration(mins);
  }

  routeToAdminDispatch(item) {
    const jobId = item?.object?.jobId || '';
    return Const.routeAdminDispatch(jobId);
  }

  onBtnDeleteTask(item) {
    DialogService.openFormDialog(RemoveWorkTaskDialog, {
      nzData: {
        taskId: item.id,
        jobId: item.object?.jobId,
        closeOnSuccess: true,
        updateSuccess: () => {
          this.reload.emit();
        }
      },
      nzMaskClosable: true,
      nzClassName: 'modal-no-padding',
      nzWidth: '460px'
    });
  }

  resetListSelected() {
    this.setOfSelectedId = {};
    this.selectAll = false;
    this.refreshSelectedAll();
  }

  onAllSelected(event: any) {
    if (!event) {
      for (let item of this.displayInfo.listData) {
        delete this.setOfSelectedId[item.id];
      }
    } else {
      for (let item of this.displayInfo.listData) {
        this.setOfSelectedId[item.id] = item;
      }
    }
    this.selectAll = !this.selectAll;
  }

  onItemSelected(row: any) {
    if (!row) return;
    if (this.setOfSelectedId[row.id]) {
      delete this.setOfSelectedId[row.id]
    } else {
      this.setOfSelectedId[row.id] = row
    }
    this.refreshSelectedAll()
  }

  refreshSelectedAll() {
    let total = this.displayInfo.listData.length;
    let selected = this.displayInfo.listData.filter(it => !!this.setOfSelectedId[it.id]).length;
    this.selectAll = total > 0 && selected == total;
    this.indeterminate = selected > 0 && !this.selectAll;
  }

  stopPropergate(event) {
    event.stopPropagation();
  }

  getSelectedItems() {
    return Object.values(this.setOfSelectedId) || []
  }

  get canBulkAssign() {
    return this.getSelectedItems().length > 0;
  }

  onBtnBulkAssign() {
    DialogService.openFormDialog(BulkAssignTaskDialog, {
      nzData: {
        tasks: this.getSelectedItems(),
        listOperators: this.listOperators,
        closeOnSuccess: true,
        updateSuccess: () => {
          this.reload.emit();
        }
      },
      nzMaskClosable: true,
      nzClassName: 'modal-no-padding',
      nzWidth: '460px'
    });
  }
  
}