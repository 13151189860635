<form [formGroup]="formInput" nz-form class="form-container">
  <div class="flex">
    <div class="bottom10" style="font-size: 20px;">Remove Task</div>
  </div>
  <div>
    <div>Select task to remove</div>
    <nz-radio-group [formControlName]="'removeType'">
      <label nz-radio nzValue="taskOnly" class="label-item">Remove this task only</label>
      <label nz-radio nzValue="allRouteTasks" class="label-item">Remove all upcoming tasks associated with this route</label>
    </nz-radio-group>
  </div>
  <div class="separator h top10 bottom10"></div>
  <div class="form-footer">
    <button nz-button nzType="primary" nzDanger="true" [nzLoading]="isLoading" [disabled]="isLoading" (click)="onBtnRemoveTask()">Remove</button>
    <button nz-button nzType="default" [disabled]="isLoading" (click)="onBtnCancel()">Cancel</button>
  </div>
</form>
