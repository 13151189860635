<ul class="sidebar" [ngClass]="{'sm': isSmallWidth}">
  <div class="logo" [ngClass]="{'sm': isSmallWidth}">
    <img *ngIf="isSmallWidth" alt="logo-small" class="logo-small" src="assets/img/warp_logo_small.png">
    <img *ngIf="!isSmallWidth" alt="logo-full" class="logo-large" src="assets/img/warp_logo_yellow.png">
  </div>
  <div class="menu-list">
    <ng-container *ngFor="let item of menuList">
      <li class="side-item" [ngClass]="{'li-hilight':item.hilight===true}" *ngIf="(!item.children || item.children.length === 0) && shouldShowMenuItem(item)">
        <ng-container>
          <a class="side-link" [routerLink]="[item.link]" [routerLinkActive]="['active-link']">
            <i *ngIf="item.glyph" class="{{item.glyph}}"></i>
            <i *ngIf="item.nzIcon" nz-icon [nzType]="item.nzIcon" nzTheme="outline" class="icon-menu-item"></i>
            <label>{{getMenuItemName(item)}}</label>
          </a>
        </ng-container>
      </li>
  
      <nz-collapse *ngIf="item.children && item.children.length > 0 && shouldShowMenuItem(item)" [ngClass]="{'li-hilight':shouldHilightItem(item)}">
        <nz-collapse-panel class="menu-children" [nzHeader]="itemCollapse" [nzShowArrow]="false">
          <div *ngIf="!isSmallWidth">
            <ul class="ul-sub-item">
              <ng-container *ngFor="let child of item.children">
                <li class="sub-item" [ngClass]="{'li-hilight':child.hilight===true}" *ngIf="shouldShowMenuChildItem(child)">
                  <a class="side-link" [routerLink]="getRouterLink(item, child)" [routerLinkActive]="['router-link-active']">
                    <i *ngIf="child.glyph" class="{{child.glyph}}"></i>
                    <i *ngIf="child.nzIcon" nz-icon [nzType]="child.nzIcon" nzTheme="outline"></i>
                    <label>{{getMenuItemName(child)}}</label>
                  </a>
                </li>
              </ng-container>
            </ul>
          </div>
        </nz-collapse-panel>
        <ng-template #itemCollapse>
          <li class="side-item" (click)="showSubMenu($event, item, menu)">
            <a class="side-link">
              <i nz-icon [nzType]="item.nzIcon ? item.nzIcon : ''" nzTheme="outline"></i>
              <label>{{getMenuItemName(item)}}</label>
              <i *ngIf="!isSmallWidth" class="side-collapse-arrow" nz-icon [nzType]="item.collapse ? 'right' : 'down'" nzTheme="outline"></i>
            </a>
          </li>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu class="sidebar-submenu">
              <ng-container *ngFor="let child of item.children">
                <li nz-menu-item [ngClass]="{'li-hilight':child.hilight===true}" *ngIf="shouldShowMenuChildItem(child)">
                  <a class="side-link" [routerLink]="getRouterLink(item, child)" [routerLinkActive]="['router-link-active']">
                    <i *ngIf="child.glyph" class="{{child.glyph}}"></i>
                    <i *ngIf="child.nzIcon" nz-icon [nzType]="child.nzIcon" nzTheme="outline"></i>
                    <label>{{child.name}}</label>
                  </a>
                </li>
              </ng-container>
            </ul>
          </nz-dropdown-menu>
        </ng-template>
      </nz-collapse>
    </ng-container>
  </div>
  
  <div class="sidebar-submenu footer">

  </div>
  <i nz-icon class="icon-menu" [nzType]="isSmallWidth ? 'menu-unfold' : 'menu-fold'" (click)="toggleSidebar()"></i>
  <div class="version" [ngClass]="{'sm': isSmallWidth}">{{version}}</div>
  <div class="build-when" [ngClass]="{'sm': isSmallWidth}">{{buildWhen}}</div>
  <div *ngIf="isDevFeature" class="dev-feature" [ngClass]="{'sm': isSmallWidth}">
    <div>{{devFeature}}</div>
  </div>
</ul>
