import { BaseComponent } from "@abstract/BaseComponent";
import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, RouterModule } from "@angular/router";
import { setManagerDashboard } from "@services/index";
import { TopnavAppComponent } from "./topnav";
import { Const } from "@const/Const";
import { verifyWhileUsingDashboard } from "@services/auth.check-role";
import { MasterData } from "@services/master.data";
import { UIHelper } from "@services/UIHelper";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { NzIconModule } from "ng-zorro-antd/icon";
import { AppSidebarComponent } from "./sidebar/sidebar.component";
import { sideMenu } from "./sidebar/sidebar-menu";
import { PermissionManager } from "@services/permission-manager";
import { NzAffixModule } from "ng-zorro-antd/affix";
import { Utils } from "@services/utils";

const MEDIA_BREAKPOINT_DOWN_MD_WIDTH = 1199;

const CustomInputs = [
  NgIf, NgFor, NgClass,
  NzIconModule,
  NzAffixModule,
  RouterModule,
  AppSidebarComponent,
  TopnavAppComponent,
]
@Component({
  selector: '[work-manager-dashboard]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.scss']
})

export class ManagerDashboardComponent extends BaseComponent {
  private widthSmall: boolean = false;
  public isLoadingMasterData;
  private activatedComponent;
  private pendingNavigationEndUrl;

  @ViewChild('topBar', {static: true}) topBar: TopnavAppComponent;
  @ViewChild('sideBar', {static: false}) sideBar: AppSidebarComponent;

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    setManagerDashboard(this);
  }

  ngOnInit() {
    if (!this.isLoggedIn) {
      return this.goLogin();
    }
    // Cần check lại do permission có thể bị thay đổi
    verifyWhileUsingDashboard(this.authUser, this.router);

    this.getMasterData();
    super.ngOnInit();
    if (this.router.url === Const.routeDashboard) {
      this.doInitialRouting();
    }
  }

  doInitialRouting() {
    let link = this.getInitialLink(this.authUser);
    if (link) {
      this.router.navigate([link]);
    }
  }

  private getInitialLink(authUser) {
    for (let item of sideMenu) {
      if (item.permissions) {
        if (!PermissionManager.userHasOneOfPermissions(authUser, item.permissions)) {
          continue;
        }
      }
      if (!item.children) {
        return `/dashboard/${item.link}`;
      }
      for (let child of item.children) {
        if (PermissionManager.userHasOneOfPermissions(authUser, child.permissions) || (!child.permissions)) {
          let path = this.getRouterLink(item, child);
          return `/dashboard/${path}`;
        }
      }
    }
    return null;
  }

  getRouterLink(item, child) {
    return child.isUseParentPath ? `${item.link}/${child.link}` : child.link;
  }
  
  onRouterActivate(compRef) {
    compRef.parentComponent = this;   // necessary for HTMLElementWidthChangedListener
    this.activatedComponent = compRef;
    if (this.pendingNavigationEndUrl) {
      if (Utils.isFunction(this.activatedComponent.onNavigationEnd)) {
        this.activatedComponent.onNavigationEnd(this.pendingNavigationEndUrl);
      }
      this.pendingNavigationEndUrl = null;
    }
  }

  onRouterDeactivate(compRef) {
  }

  getMasterData() {
    this.isLoadingMasterData = true;
    this.api.GET(Const.API_PUBLIC('masters')).subscribe({
      next: resp => { 
        MasterData.set(resp.data);
        this.isLoadingMasterData = 'OK';
      }, 
      error: err => {
        UIHelper.showErr(err);
        this.isLoadingMasterData = 'NG';
      }
    });
  }

  onResize(event) {
    if (event.target.innerWidth <= MEDIA_BREAKPOINT_DOWN_MD_WIDTH && !this.widthSmall) {
      this.setWidthSmall(true);
    } else if (event.target.innerWidth > MEDIA_BREAKPOINT_DOWN_MD_WIDTH && this.widthSmall) {
      this.setWidthSmall(false);
    }
  }

  private setWidthSmall(value: boolean) {
    if (this.widthSmall == value) {
      return;
    }
    this.widthSmall = value;
    if (this.sideBar) {
      this.sideBar.isSmallWidth = value;
    }
  }

  updatePageTitle(str: string){
    this.topBar.setTopbarTitleFixed(str);
  }

  public onNavigationEnd(url) {
    if (!this.activatedComponent) {
      if (url.length > '/'.length) {
        this.pendingNavigationEndUrl = url;
      }
    } else if (this.activatedComponent instanceof BaseComponent) {
      (<BaseComponent>this.activatedComponent).onNavigationEnd(url);
    }
  }
}