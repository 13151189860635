import { BaseComponent } from "@abstract/BaseComponent";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Output } from "@angular/core";
import { NzIconModule } from "ng-zorro-antd/icon";
import { sideMenu } from "./sidebar-menu";
import { NavigationEnd, RouterModule } from "@angular/router";
import { PermissionManager } from "@services/permission-manager";
import { Const } from "@const/Const";
import { getManagerDashboard } from "@services/index";
import { Log } from "@services/log";
import { AppConst } from "@app/const.generated";
import { environment } from "@env/environment";
import { EnvType } from "@env/type";
import { NzContextMenuService, NzDropdownMenuComponent, NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzCollapseModule } from "ng-zorro-antd/collapse";

const CustomInputs = [
  NgIf, NgFor, NgClass,
  NzIconModule,
  NzDropDownModule,
  NzToolTipModule,
  NzCollapseModule,
  RouterModule
]

@Component({
  selector: '[app-sidebar]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class AppSidebarComponent extends BaseComponent {
  @Output() onExpadCollapse = new EventEmitter<any>();
  @Output() notifyPageTitle: EventEmitter<any> = new EventEmitter<any>();
  public menuList = sideMenu;

  constructor(private nzContextMenuService: NzContextMenuService) {
    super();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.hilightMenuItem(event.url);
      }
    })
    this.assignIdForMenuItems();
  }

  ngOnInit() {
    this.setupMenuList();
    super.ngOnInit();
  }

  private setupMenuList() {
    this.hilightMenuItem(this.router.url);
  }


  private _isSmallWidth: boolean = false;

  set isSmallWidth(value: boolean) {
    if (value !== this._isSmallWidth) {
      this.toggleSidebar();
    }
  }
  get isSmallWidth(): boolean {
    return this._isSmallWidth;
  }

  get version(): string {
    return `Version ${AppConst.Version}`;
  }
  get buildWhen(): string {
    if (environment.type == EnvType.prod) {
      return '';
    }
    return `Build ${AppConst.BuildWhen}`;
  }
  get devFeature(): string {
    return AppConst.devFeatureBranch;
  }
  get isDevFeature(): boolean {
    return /^dev[0-9]+$/.test(environment.type);
  }

  toggleSidebar() {
    this._isSmallWidth = !this._isSmallWidth;
    this.onExpadCollapse.emit(this._isSmallWidth);
    let sidebarEl = document.querySelector(".sidebar-container .ant-affix") as HTMLElement
    if (sidebarEl) {
      let sidebarWidth = this._isSmallWidth ? "50px" : "250px";
      sidebarEl.style.width = sidebarWidth;
    }
    let sidebarEl2 = document.querySelector(".sidebar-container nz-affix") as HTMLElement
    if (sidebarEl2) {
      let sidebarWidth = this._isSmallWidth ? "50px" : "250px";
      sidebarEl2.style.width = sidebarWidth;
    }
  }

  private assignIdForMenuItems() {
    for (let i = 0; i < this.menuList.length; i++) {
      this.menuList[i].collapse = true;
      if (!this.menuList[i].children) {
        continue;
      }
      for (let j = 0; j < this.menuList[i].children.length; j++) {
        this.menuList[i].children[j].id = this.menuList[i].id + '/' + this.menuList[i].children[j].link;
      }
    }
  }

  private hilightMenuItem(urlStr: string) {
    let url = urlStr.split('?')[0];
    if (!url.endsWith('/')) {
      url += '/';
    }
    let foundHilightItem = false;
    let pageTitle;
    for (let i = 0; i < this.menuList.length; i++) {
      if (this.menuList[i].children?.length > 0) {
        for (let j = 0; j < this.menuList[i].children.length; j++) {
          let path = this.getRouterLink(this.menuList[i], this.menuList[i].children[j]);
          let itemLink = `${Const.routeDashboard}/${path}`;
          this.menuList[i].children[j].hilight = url.indexOf(itemLink) === 0;
          if (this.menuList[i].children[j].hilight && this.canAccessMenuItem(this.menuList[i].children[j])) {
            foundHilightItem = true;
            this.menuList[i].collapse = false;
            pageTitle = this.menuList[i].children[j].name;
          }
        }
      }
      else {
        let path = this.getRouterLink(this.menuList[i]);
        let itemLink = `${Const.routeDashboard}/${path}`;
        this.menuList[i].hilight = url.indexOf(itemLink) === 0;
        if (this.menuList[i].hilight && this.canAccessMenuItem(this.menuList[i])) {
          foundHilightItem = true;
          pageTitle = this.menuList[i].name;
        }
      }
      let isDashboardUrl = url.startsWith(Const.routeDashboard);
      if (isDashboardUrl && !foundHilightItem) {
        Log.e('Found no item to hilight on sidebar menu -> this might be an illegal accessing by entering url');
        getManagerDashboard()?.doInitialRouting();
      }
      if (foundHilightItem) {
        this.notifyPageTitle.emit(pageTitle);
      }
    }
  }

  // Chỉ dùng cho trường hợp menu có children
  // Nếu item có 1 thằng con đang hilight thì khi collapse lại sẽ hilight item đó
  // Khi expand ra thì lại chuyển hilight cho thằng con
  shouldHilightItem(item): boolean {
    if (!this.isSmallWidth && !item.collapse) {
      return false;
    }
    if (!item.children || item.children.length == 0) {
      return item.hilight;
    }
    for (const child of item.children) {
      if (child.hilight) {
        return true;
      }
    }
    return false;
  }

  shouldShowMenuItem(item: SidebarMenuItem) {
    if (item.hidden) return false;
    if (item.children && item.children.length > 0) {
      for (const child of item.children) {
        if (this.shouldShowMenuChildItem(child)) {
          return true;
        }
      }
      return false;
    }
    return this.canAccessMenuItem(item);
  }

  shouldShowMenuChildItem(item: SidebarMenuChildItem) {
    if (item.hidden) return false;
    return this.canAccessMenuItem(item);
  }

  private canAccessMenuItem(item: SidebarMenuChildItem | SidebarMenuItem): boolean {
    if (!item.permissions || item.permissions.length == 0) return true;
    // all permissions are required
    for (const permission of item.permissions) {
      if (!PermissionManager.userHasPermission(this.authUser, permission)) {
        return false;
      }
    }
    return true;
  }

  getRouterLink(item, child?) {
    if (!child) return item.link;
    if (child.specificParentPath) {
      // Nếu có specific path thì dùng luôn thay thế cho parent path (item.link) 
      return `${child.specificParentPath}/${child.link}`;
    }
    return child.isUseParentPath ? `${item.link}/${child.link}` : child.link;
  }

  getMenuItemName(item) {
    return this.isSmallWidth ? '' : item.name;
  }

  getMenuItemTooltip(item) {
    if (item && this.isSmallWidth) return item.name;
    return '';
  }

  // giá trị lấy từ file _common.scss
  private sidebarWidthSm: number = 50;

  showSubMenu(event, item, menu: NzDropdownMenuComponent) {
    event.stopPropagation();
    item.collapse = !item.collapse;
    if (this.isSmallWidth) {
      // Không tạo submenu tại vị trí click mà đặt ra rìa sidebar
      let placement = {
        x: this.sidebarWidthSm,
        y: event.y
      }
      this.nzContextMenuService.create(placement, menu);
    }
  }
}